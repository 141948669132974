<template>
  <div class="containerCnt">
    <div class="contentCnt">
      <div class="btns">
        <button>صفحه اصلی</button>
        <button @click="toggleMenu(0)">لیست <ArrowDown /></button>
        <ul
          :class="{ active: activeMenu === 0 }"
          ref="menu0"
          class="menus menu0"
        >
          <li>دانش‌بنیان</li>
          <li>خلاق</li>
          <li>استارت‌آپ</li>
          <li>پارک‌های علم و فناوری</li>
          <li>شتاب‌دهنده‌ها</li>
          <li>فضای کار اشتراکی</li>
          <li>مراکز رشد</li>
          <li>مناطق آزاد ایران</li>
          <li>کارآفرین‌ها</li>
        </ul>
        <button @click="toggleMenu(1)">رویدادها <ArrowDown /></button>
        <ul
          :class="{ active: activeMenu === 1 }"
          ref="menu1"
          class="menus menu1"
        >
          <li @click="showEvent()">استیج</li>
          <li @click="showEvent()">سرمایه‌گذاری</li>
          <li @click="showEvent()">داستان من</li>
          <li @click="showEvent()">مصاحبه</li>
          <li @click="showEvent()">پنل گفت‌وگو</li>
          <li @click="showEvent()">کافه دورهمی</li>
        </ul>
        <button @click="toggleMenu(2)">خبرنامه <ArrowDown /></button>
        <ul
          :class="{ active: activeMenu === 2 }"
          ref="menu2"
          class="menus menu2"
        >
          <li>خبر روز</li>
          <li>دستورالعمل‌ها</li>
          <li>خط زمانی</li>
          <li>برنامه حمایتی</li>
        </ul>
        <button @click="toggleMenu(3)">مجله‌نامه <ArrowDown /></button>
        <ul
          :class="{ active: activeMenu === 3 }"
          ref="menu3"
          class="menus menu3"
        >
          <li>اینفوگرافی</li>
          <li>موشن‌گرافی</li>
          <li>ویدئو موشن</li>
          <li>گالری</li>
        </ul>
        <button @click="toggleMenu(4)">تماس با ما <ArrowDown /></button>
        <ul
          :class="{ active: activeMenu === 4 }"
          ref="menu4"
          class="menus menu4"
        >
          <li @click="showContactUs()">ارتباط با ما</li>
          <li>همکاری داوطلبانه</li>
          <li>سخنران‌ها</li>
          <li>حامیان مالی</li>
        </ul>
      </div>
      <!-- دکمه بستن -->
      <div @click="hideMenu" class="delBtn">&#x2715;</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import ArrowDown from "@/assets/svg , icon/ArrowDownRed.vue";

export default {
  name: "HeaderMobile",
  components: {
    ArrowDown,
  },
  setup() {
    const activeMenu = ref(null);

    // تغییر وضعیت نمایش منوها
    const toggleMenu = (index) => {
      activeMenu.value = activeMenu.value === index ? null : index;
    };

    // بستن همه منوها
    const closeAllMenus = () => {
      activeMenu.value = null;
    };

    // مدیریت کلیک در خارج از منو
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".contentCnt")) {
        closeAllMenus();
      }
    };

    // مدیریت دکمه بستن
    const hideMenu = () => {
      const menu = document.getElementById("myMenuCnt");
      menu.style.right = "-100%";
    };

    // افزودن و حذف Event Listeners
    onMounted(() => {
      document.addEventListener("click", handleOutsideClick);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleOutsideClick);
    });

    // مدیریت اسکرول به بخش‌های خاص
    const showEvent = () => {
      const target = document.getElementById("eventsContent");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    };

    const showContactUs = () => {
      const target = document.getElementById("showContactUs");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      activeMenu,
      toggleMenu,
      hideMenu,
      showEvent,
      showContactUs,
    };
  },
};
</script>

<style scoped src="@/assets/css/headerMoblie.css"></style>
