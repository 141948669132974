<template>
  <div id="showContactUs" class="container">
    <div class="contentUs">
      <p>ارتباط با ما</p>
      <ul class="example-2">
        <div class="firstContent">
          <li class="icon-content">
            <a
              href="https://linkedin.com"
              aria-label="LinkedIn"
              data-social="linkedin"
            >
              <div class="filled"></div>
              <linkdinIcon />
            </a>
            <div class="tooltip">LinkedIn</div>
          </li>
          <li class="icon-content">
            <a href="tel:+989909555594">
              <div class="filled"></div>
              <phoneIcon />
            </a>
            <div class="tooltip">Phone</div>
          </li>
          <li class="icon-content">
            <a
              href="https://www.instagram.com/irirooydad"
              aria-label="Instagram"
              data-social="instagram"
            >
              <div class="filled"></div>
              <instagramIcon />
            </a>
            <div class="tooltip">Instagram</div>
          </li>
          <li class="icon-content">
            <a
              href="https://telegram.org/IRIROOYDAD"
              aria-label="Telegram"
              data-social="telegram"
            >
              <div class="filled"></div>
              <TelegramIcon />
            </a>
            <div class="tooltip">Telegram</div>
          </li>
        </div>
        <div class="secundContent">
          <li class="icon-content">
            <a
              href="https://www.youtube.com/@irirooydad"
              aria-label="youtube"
              data-social="youtube"
            >
              <div class="filled"></div>
              <youtubIcon />
            </a>
            <div class="tooltip">Youtube</div>
          </li>

          <li class="icon-content">
            <a
              href="https://aparat.com"
              aria-label="aparat"
              data-social="aparat"
            >
              <div class="filled"></div>
              <aparatIcon />
            </a>
            <div class="tooltip">aparat</div>
          </li>

          <li class="icon-content">
            <a
              href="https://twitter"
              aria-label="twitter"
              data-social="twitter"
            >
              <div class="filled"></div>
              <twitterIcon />
            </a>
            <div class="tooltip">twitter</div>
          </li>

          <li class="icon-content">
            <a
              href="https://facebook.com"
              aria-label="facebook"
              data-social="facebook"
            >
              <div class="filled"></div>
              <facebookIcon />
            </a>
            <div class="tooltip">facebook</div>
          </li>
        </div>
      </ul>
    </div>
    <div class="logo">
      <SorenaLogo />
      <p>طراحی نرم افزار توسط شرکت سانا فناوری سورنا</p>
      <a href="https://sstech.ir/">sstech.ir</a>
    </div>
  </div>
</template>

<script>
import phoneIcon from "@/assets/svg , icon/ContactUs  svg , icon/phone-call.vue";
import TelegramIcon from "@/assets/svg , icon/ContactUs  svg , icon/TelegramIcon.vue";
import instagramIcon from "@/assets/svg , icon/ContactUs  svg , icon/instagramIcon.vue";
import linkdinIcon from "@/assets/svg , icon/ContactUs  svg , icon/linkdinIcon.vue";
import aparatIcon from "@/assets/svg , icon/ContactUs  svg , icon/AparatIcon.vue";
import youtubIcon from "@/assets/svg , icon/ContactUs  svg , icon/youtubIcon.vue";
import twitterIcon from "@/assets/svg , icon/ContactUs  svg , icon/twitterIcon.vue";
import facebookIcon from "@/assets/svg , icon/ContactUs  svg , icon/facebookIcon.vue";
import SorenaLogo from "@/assets/svg , icon/ContactUs  svg , icon/SorenaLogo.vue";

export default {
  components: {
    phoneIcon,
    TelegramIcon,
    instagramIcon,
    linkdinIcon,
    aparatIcon,
    youtubIcon,
    twitterIcon,
    facebookIcon,
    SorenaLogo,
  },
};
</script>

<style scoped src="@/assets/css/footer.css"></style>
